<template>
  <div class="edit-message">
    <CForm
      ref="editNotificationMessage"
      v-on:submit.prevent="updateNotificationMessage"
      novalidate
      class="edit-message-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editNotification.title"
        submit-title="update"
      />
      <div class="form-content content">
        <!--    PAGE TOOLBAR    -->
        <ClPageToolbar
          isAdditionalParam
          @toggleAdditionalParam="handleToggleAdditionalParam"
          @toggleEditorToolbar="handleToggleEditorToolbar"
        />
        <!--   Message Type   -->
        <ClFormRow
          :label="texts.editNotification.typeLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.messageType"
              :options="messageTypeOptions"
              :multiple="false"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              @tag="addMessageType"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="messageTypeValidate"
              required
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': messageTypeValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--   Status   -->
        <ClFormRow
          :label="texts.editNotification.statusLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.status"
              :options="statusOptions"
              :multiple="false"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              @tag="addStatus"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="statusValidate"
              required
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': statusValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--   Members   -->
        <ClFormRow
          :label="texts.editNotification.membersLabel"
          :toolTipText="descriptions.members"
        >
          <template #content>
            <multiselect
              v-model="formData.memberId"
              :options="memberOptions"
              :multiple="true"
              :preserve-search="true"
              :hide-selected="true"
              :taggable="true"
              @tag="addMember"
              :selectLabel="selectLabel"
              :searchable="false"
              :block-keys="['Tab', 'Enter']"
              :is-valid="memberIdValidate"
              required
              label="name"
              track-by="id"
              class="custom-multi-select zq--multiselect"
              :class="{'zq--select--error': memberIdValidate === false}"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </multiselect>
            <img
              src="../../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--   MEMBERS GROUP     -->
        <ClFormRow
          :label="texts.editNotification.memberGroupsLabel"
          :toolTipText="descriptions.memberGroups"
        >
          <template #content>
            <multiselect
              v-model="formData.memberGroups"
              :options="memberGroupOptions"
              :multiple="true"
              :preserve-search="true"
              :taggable="true"
              :hide-selected="true"
              @tag="addGroups"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              class="custom-multi-select zq--multiselect"
            />
            <img
              src="../../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--  Subject  -->
        <ClFormRow
          :label="texts.editNotification.subjectLabel"
          :toolTipText="descriptions.subject"
        >
          <template #content>
            <CInput
              :placeholder="texts.editNotification.subjectPlaceholder"
              v-model="formData.subject"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="subjectValidate"
            />
          </template>
        </ClFormRow>
        <!--  BODY-->
        <ClFormRow
          :label="texts.editNotification.bodyLabel"
          :toolTipText="descriptions.body"
        >
          <template #content>
            <ClEditor
              :is-toolbar="isToolbar"
              @change="onBodyChange"
              :value-prop="formData.body"
              :placeholder="texts.editNotification.bodyPlaceholder"
              :options="bodyOptions"
              :is-valid="bodyValidate"
            />
          </template>
        </ClFormRow>
        <!-- Schedule Type   -->
        <ClFormRow
          :label="texts.editNotification.scheduleTypeLabel"
          xl-content="5"
          :toolTipText="descriptions.membersCanAchieveOn"
        >
          <template #content>
            <ClSelect
              class="text-capitalize"
              :options="occurrencesLimitOption"
              :placeholder="texts.editNotification.scheduleTypePlaceholder"
              :valueProp="formData.scheduling.scheduleType"
              size="xxl"
              required
              :selectLabel="selectLabel"
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="scheduleTypeValidate"
              @checkValue="scheduleTypeUpdate"
            />
          </template>
        </ClFormRow>
        <!--  Schedule Occurrences Limit  -->
        <ClFormRow
          xl-content="5"
          :label="texts.editNotification.occurrencesLabel"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="texts.editNotification.occurrencesPlaceholder"
              v-model.number="formData.scheduling.scheduleOccurrencesLimit"
              add-input-classes="col-sm-12"
              min="1"
              step="1"
              @update:value="handleUpdateLimit"
              :invalidFeedback="texts.invalidFeedback"
              :isValid="scheduleOccurrencesLimitValidate"
            />
          </template>
        </ClFormRow>
        <!--    When    -->
        <ClFormRow
          :label="texts.editNotification.whenLabel"
          xl-content="5"
          v-if="isEveryVisible"
        >
          <template #content>
            <multiselect
              v-model="everyChecked"
              :options="every"
              label="label"
              track-by="value"
              :multiple="true"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              :preserve-search="true"
              :hide-selected="true"
              class="custom-multi-select zq--multiselect"
            />
            <img
              src="../../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--    FROM    -->
        <ClFormRow
          :label="texts.editNotification.startLabel"
          xl-content="5"
          :toolTipText="descriptions.achievementAvailableFrom"
        >
          <template #content>
            <DatePicker
              :dateProp="startDate"
              @input="setStartDate"
              :placeholder="texts.editNotification.startPlaceholder"
              class="schedule-startDate"
              :class="{'zq--select--error': startDateValidate === false}"
            />
          </template>
        </ClFormRow>
        <!--     TILL   -->
        <ClFormRow
          :label="texts.editNotification.endLabel"
          xl-content="5"
          :toolTipText="descriptions.till"
        >
          <template #content>
            <DatePicker
              :dateProp="endDate"
              @input="setEndDate"
              :placeholder="texts.editNotification.endPlaceholder"
            />
          </template>
        </ClFormRow>
        <!--    Additional Param     -->
        <div v-show="showParam">
          <!--     Tags     -->
          <ClFormRow :label="texts.editNotification.tagsLabel" tool-tip-text="Tags">
            <template #content>
              <multiselect
                v-model="formData.tags"
                :options="tagOptions"
                :multiple="true"
                :preserve-search="true"
                :taggable="true"
                :hide-selected="true"
                :selectLabel="selectLabel"
                :tagPlaceholder="tagPlaceholder"
                @tag="addTags"
                class="custom-multi-select zq--multiselect"
              />
              <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon"
              >
            </template>
          </ClFormRow>
          <!--    CUSTOM FIELDS    -->
          <ClFormRow :label="texts.editNotification.customFieldsLabel" :toolTipText="descriptions.customFields">
            <template #content>
              <multiselect
                v-model="formData.customFields"
                :options="customFieldsOptions"
                :multiple="true"
                :preserve-search="true"
                :taggable="true"
                :hide-selected="true"
                @tag="addCustomFields"
                :selectLabel="selectLabel"
                :tagPlaceholder="tagPlaceholder"
                label="name"
                track-by="id"
                :placeholder="texts.editNotification.customPlaceholder"
                class="custom-multi-select zq--multiselect__search zq--multiselect"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
              </multiselect>
              <img
                src="../../../../../assets/icons/search.svg"
                width="16"
                height="16"
                alt="search"
                class="zq--multiselect-search-icon"
              >
            </template>
          </ClFormRow>
          <!--    METADATA    -->
          <ClFormRow
            :label="texts.editNotification.metadataLabel"
            tooltipPosition="label"
            xl-label="12"
            md-content="12"
            xl-content="12"
          >
            <template #content>
              <CMetaInputs
                v-on:change="addMeta"
                :fields="formData.metadata"
              />
            </template>
          </ClFormRow>
        </div>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CreateHeader from "@/shared/components/CreateHeader";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClEditor from "@/shared/components/formComponents/ClEditor";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import ClPageToolbar from '@/shared/components/ClPageToolbar';
import CMetaInputs from '@/components/forms/CMetaInputs';
import DatePicker from '@/shared/components/DatePicker';
import {messaging} from "@/config/descriptions/messaging.json";
import {formConfig, pageConfig} from "@/config";
import {cloneDeep, range} from "lodash";
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import {inboxTexts} from "@/config/pageTexts/inbox.json";

export default {
  name: "EditNotificationMessage",
  components: {
    CreateHeader,
    ClFormRow,
    ClEditor,
    ClSelect,
    ClPageToolbar,
    CMetaInputs,
    DatePicker,
  },
  data() {
    return {
      formData: {
        id: '',
        memberId: [],
        memberGroup: [],
        messageType: '',
        subject: '',
        body: '',
        status: '',
        scheduling: {
          scheduleType: '',
          scheduleOccurrencesLimit: '',
          every: '',
          startDate: '',
          endDate: '',
        },
        translations: [],
        tags: [],
      },
      everyChecked: [],
      showParam: false,
      isToolbar: false,
      startDate: '',
      endDate: '',
      descriptions: {
        ...messaging.create.message
      },
      texts: {
        ...inboxTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      bodyOptions: {
        placeholder: 'Enter your message here',
      },
      messageTypeOptions: [
        'Achievement',
        'Ticket',
        'Reward',
        'Text',
        'Notification',
        'InboxItem',
        'NotificationInboxItem',
      ],
      statusOptions: [
        'New',
        'Draft',
      ],
      tagOptions: ['tag1', 'tag2', 'tag3'],
      memberGroupOptions: ['group1', 'group2', 'group3'],
      occurrencesLimitOption: ['Once', 'Repeatedly', 'Daily', 'Weekly', 'Monthly'],
      everyDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      everyMonth: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      messageTypeValidate: null,
      subjectValidate: null,
      bodyValidate: null,
      statusValidate: null,
      memberIdValidate: null,
      scheduleTypeValidate: null,
      scheduleOccurrencesLimitValidate: null,
      startDateValidate: true,
      customFieldsOptions: [],
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  computed: {
    ...mapGetters('messages', ['loading', 'singleMessage']),
    ...mapGetters('members', ['members']),
    isEveryVisible() {
      return this.formData.scheduling.scheduleType === 'Daily' ||
        this.formData.scheduling.scheduleType === 'Monthly' ||
        this.formData.scheduling.scheduleType === 'Weekly';
    },
    memberOptions() {
      let memberOptions = [];
      if (this.members) {
        this.members.forEach(member => {
          memberOptions.push({
            name: member.name,
            id: member.id
          })
        })
      }
      return memberOptions;
    },
    every() {
      if (this.formData.scheduling.scheduleType === 'Daily') return this.arrayToEvery(this.everyDay);
      if (this.formData.scheduling.scheduleType === 'Monthly') return this.arrayToEvery(this.everyMonth);
      if (this.formData.scheduling.scheduleType === 'Weekly') return this.weeklyToEvery(range(1, 53));
      return null;
    },
  },
  watch: {
    singleMessage: {
      deep: true,
      handler: function (val) {
        this.formData = cloneDeep(val);
        if (!this.formData.scheduling) {
          this.formData.scheduling = {
            scheduleType: '',
            scheduleOccurrencesLimit: '',
            every: '',
            startDate: '',
            endDate: '',
          };
        }
      },
    },
    formData: {
      deep: true,
      handler: function (val) {
        this.validateFields(val);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('messages', ['handleGetNotificationMessageById', 'handleUpdateNotificationMessage']),
    ...mapActions('members', ['handleGetMembers']),
    ...mapActions('customFields', ['handleGetCustomFields']),
    initialize() {
      this.handleGetNotificationMessageById(this.$route.params.id)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].subject
          });
        });
      this.handleGetMembers({
        queryData: {},
        sortBy: this.sortBy,
        skip: 0,
      });
      this.handleGetCustomFields({
        queryData: {},
        sortBy: this.sortBy,
        skip: 0,
        limit: this.itemsPerPage,
      }).then((items) => {
        items.forEach(item => {
          this.customFieldsOptions.push({id: item.id, name: item.name});
        })
      });
    },
    async updateNotificationMessage() {
      this.messageTypeValidate = !!this.formData.messageType;
      this.subjectValidate = !!this.formData.subject;
      this.bodyValidate = !!this.formData.body;
      this.memberIdValidate = !!this.formData.memberId.length;
      if (this.formData.scheduling) {
        this.scheduleTypeValidate = !!this.formData.scheduling.scheduleType;
        this.scheduleOccurrencesLimitValidate = !!this.formData.scheduling.scheduleOccurrencesLimit;
        this.startDateValidate = !!this.formData.scheduling.startDate;
      } else {
        this.scheduleTypeValidate = false;
        this.scheduleOccurrencesLimitValidate = false;
        this.startDateValidate = false;
      }

      let memberIds = [];
      this.formData.memberId.forEach(member => {
        memberIds.push(member.id);
      })

      this.formData.scheduling.every = this.every;

      if (
        this.messageTypeValidate
        && this.subjectValidate
        && this.bodyValidate
        && this.statusValidate
        && this.memberIdValidate
        && this.scheduleTypeValidate
        && this.scheduleOccurrencesLimitValidate
        && this.startDateValidate
      ) {
        const body = {
          id: this.formData.id,
          messageType: this.formData.messageType,
          subject: this.formData.subject,
          body: this.formData.body,
          status: this.formData.status,
          memberId: memberIds,
          memberGroup: this.formData.memberGroups,
          scheduling: this.formData.scheduling,
          customFields: this.formData.customFields,
          tags: this.formData.tags,
          metadata: this.formData.metadata,
          translations: this.formData.translations,
        };

        await this.handleUpdateNotificationMessage(body);
        await this.$router.push({
          name: 'PreviewNotification',
          id: this.$route.params.id,
        });
      }
    },
    validateFields(val) {
      if (val.body) this.bodyValidate = true;
      if (val.subject) this.subjectValidate = true;
      if (val.scheduling) {
        if (val.scheduling.scheduleType) this.scheduleTypeValidate = true;
        if (val.scheduling.scheduleOccurrencesLimit) this.scheduleOccurrencesLimitValidate = true;
        if (val.scheduling.startDate) this.startDateValidate = true;
      }
      if (val.messageType) this.messageTypeValidate = true;
      if (val.memberId.length) this.memberIdValidate = true;
    },
    handleToggleAdditionalParam(val) {
      this.showParam = val;
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
    addMessageType(messageType) {
      this.formData.messageType = messageType;
    },
    addStatus(status) {
      this.formData.status = status;
    },
    addMember(newMember) {
      this.memberOptions.push(newMember);
    },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
    addGroups(newGroup) {
      this.memberGroupOptions.push(newGroup);
      this.formData.memberGroups.push(newGroup);
    },
    addTags(newTag) {
      this.tagOptions.push(newTag);
      this.formData.tags.push(newTag);
    },
    addCustomFields(val) {
      this.customFieldsOptions.push(val);
      this.formData.customFields = [...this.formData.customFields, val]
    },
    onBodyChange(html) {
      this.formData.body = html;
    },
    scheduleTypeUpdate({value}) {
      this.formData.scheduling.scheduleType = value;
    },
    handleUpdateLimit(val) {
      if (val > 2147483647) {
        this.formData.scheduling.scheduleOccurrencesLimit = 2147483647;
      }
      if (!val || val < 1) {
        this.formData.scheduling.scheduleOccurrencesLimit = 1;
      } else {
        this.formData.scheduling.scheduleOccurrencesLimit = parseInt(val)
      }
    },
    setStartDate(date) {
      const d = Date.parse(date);
      this.formData.scheduling.startDate = date ? new Date(d).toISOString() : "";
      this.startDate = date;
    },
    setEndDate(date) {
      const d = Date.parse(date);
      this.formData.scheduling.endDate = date ? new Date(d).toISOString() : "";
      this.endDate = date;
    },
  }
}
</script>

<style lang="scss">
.edit-message {
  height: 100%;
  &-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
  .multiselect.custom-multi-select.zq--multiselect{
    .multiselect__select {
      &:before {
        display: none;
      }
    }
  }
  .zq--form-row.row .zq--form-row--content {
    max-width: 33%;
  }
  .zq--data-picker.mx-datepicker{
    width: 100%;
    min-width: auto;
  }
}
</style>